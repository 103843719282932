<template>
    <div class="buy-success">
        <div class="container">
            <div class="page-hero">
                <img draggable="false" src="@/assets/decoration/success-icon.svg" alt="">
                <h1>{{ $t('page.buy_success.title') }}</h1>
                <p>{{ $t('page.buy_success.intro') }}</p>
            </div>
            <div class="four-row">
                <div class="item">
                    <h2>{{ $t('page.buy_success.guide.download.title') }}</h2>
                    <p>{{ $t('page.buy_success.guide.download.description') }}</p>
                    <router-link to="/download"><span>{{ $t('page.buy_success.guide.download.button') }}</span><i class="iconfont">&#xe638;</i></router-link>
                </div>
                <div class="item">
                    <h2>{{ $t('page.buy_success.guide.learn.title') }}</h2>
                    <p>{{ $t('page.buy_success.guide.learn.description') }}</p>
                    <router-link to="/docs"><span>{{ $t('page.buy_success.guide.learn.button') }}</span><i class="iconfont">&#xe638;</i></router-link>
                </div>
                <div class="item">
                    <h2>{{ $t('page.buy_success.guide.service.title') }}</h2>
                    <p>{{ $t('page.buy_success.guide.service.description') }}</p>
                    <router-link to="/service"><span>{{ $t('page.buy_success.guide.service.button') }}</span><i class="iconfont">&#xe638;</i></router-link>
                </div>
                <div class="item">
                    <h2>{{ $t('page.buy_success.guide.wechat_oa.title') }}</h2>
                    <p>{{ $t('page.buy_success.guide.wechat_oa.description') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuySuccess'
}
</script>

<style lang="scss" scoped>
.buy-success {
    background: #000;
    color: #fff;

    .container:first-child {
        background: url(../assets/decoration/pop-black.svg) no-repeat;
        background-size: 100% auto;
        background-position: center top -50px;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .page-hero {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 96px;
        }
        h1 {
            font-size: 48px;
            line-height: 1;
            margin: 0;
            margin-bottom: 40px;
            margin-top: 25px;
        }
        p {
            font-size: 18px;
            text-align: center;
            opacity: .8;
            margin: 0;
            white-space: pre-wrap;
        }
    }
    .four-row {
        margin-top: 60px;

        h2 {
            font-size: 24px;
            margin: 0;
        }
        p {
            font-size: 14px;
            line-height: 1.75;
            color: #b9b9b9;
            height: 1%;
            flex-grow: 1;
            margin-bottom: 0;

            a {
                color: var(--p);
            }
        }
        a {
            display: flex;
            text-decoration: none;
            color: var(--p);
            margin-top: 1em;
            padding: 7px 15px;
            border-radius: 6px;
            background: rgba(#FF971A, .2);
            align-items: center;

            span {
                display: block;
                width: 1%;
                flex-grow: 1;
            }
            &:hover {
                background: rgba(#FF971A, .3);
            }
        }
        .item {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>